//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
    progresscom: () => import("./progressCom"),
  },
  props: {
    //是否显示截图
    IsRealTimeScreenshot: {
      type: Boolean,
      default: true,
    },
    sensitiveWord: {
      type: Array,
      default: null,
    },
    //(开始时间）
    stime: {
      type: String | Date,
      default: null,
    },
    //结束时间
    etime: {
      type: String | Date,
      default: null,
    },
    //用户id
    uid: {
      type: Number,
      default: null,
    },
    teamValue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      word: null,
      headerCellStyle: {
        textAlign: "center",
        fontSize: "18",
        background: "#f8f8f8",
        color: "#4a4a4a",
      },
    };
  },
  methods: {
    cellClick(row) {
      this.word = row.Sensitive;
      this.$modal.show("sensitiveWord");
    },
  },
};
